.navbar {
  margin: 0 2rem;
  border-radius: 0;
  padding: 0 1rem;
  font-family: "Aldrich", sans-serif;
  box-shadow: rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

.navbar .logo {
  width: 40px;
}

.navbar .logo:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
}

.ms-auto a {
  text-decoration: none;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  transition: 0.5s;
}

.ms-auto a:hover {
  padding: 15px 10px;
  text-decoration: underline;
  cursor: url("./images/cursor-hover.png"), pointer;
}

.switch-mode-mobile {
  display: none;
}

.switch-mode-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

@media (max-width: 767.5px) {
  .navbar {
    padding-block: 0.5rem;
    margin: 0 1rem;
  }
  .navbar .toggler {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    font-size: 25px;
    border-radius: 0;
    display: flex;
    align-items: center;
  }

  .ms-auto {
    height: 100vh;
    padding-block: 5rem;
    margin-inline: auto;
    display: flex;
    align-items: center;
  }
  .switch-mode-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .switch-mode-desktop {
    display: none;
  }
}

@media (max-width: 350.5px) {
  .navbar {
    padding-inline: 0.5rem;
  }
}

/* switch settings */

.switch {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 6em;
  height: 3em;
}

.switch .cb {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #3d3d3d;
  border-radius: 0;
  transition: 10s;
  font-weight: 700;
  overflow: hidden;
  box-shadow: -0.3em 0 0 0 #3d3d3d, -0.3em 0.3em 0 0 #3d3d3d,
    0.3em 0 0 0 #3d3d3d, 0.3em 0.3em 0 0 #3d3d3d, 0 0.3em 0 0 #3d3d3d;
}

.toggle:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
}

.toggle > .left {
  position: absolute;
  display: flex;
  width: 50%;
  height: 88%;
  background-color: white;
  color: black;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  transform-origin: right;
  transform: rotateX(10deg);
  transform-style: preserve-3d;
  transition: all 150ms;
}

.left::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center left;
  transform: rotateY(90deg);
}

.left::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center bottom;
  transform: rotateX(90deg);
}

.toggle > .right {
  position: absolute;
  display: flex;
  width: 50%;
  height: 88%;
  background-color: black;
  color: rgb(206, 206, 206);
  right: 1px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  transform-origin: left;
  transform: rotateX(10deg) rotateY(-30deg);
  transform-style: preserve-3d;
  transition: all 150ms;
}

.right::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center right;
  transform: rotateY(-90deg);
}

.right::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center bottom;
  transform: rotateX(90deg);
}

.switch input:checked + .toggle > .left {
  transform: rotateX(10deg) rotateY(30deg);
  color: rgb(206, 206, 206);
}

.switch input:checked + .toggle > .right {
  transform: rotateX(10deg) rotateY(0deg);
  color: white;
  background-color: black;
}
