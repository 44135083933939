#contact {
  margin: 2rem;
  box-shadow: rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
}

#contact h1 {
  text-align: center;
  font-family: "Smooch Sans", sans-serif;
  font-weight: 700;
  font-size: 5vmax;
  max-width: 50%;
  margin-inline: auto;
  padding-block: 3rem;
}

#contact .contact-form {
  max-width: 70%;
  margin-inline: auto;
}

#contact .form-control {
  background-color: transparent;
  font-family: "Aldrich", sans-serif;
  font-weight: normal;
  border-radius: 0;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-block: 0.5rem;
  margin-block: 1rem;
}

#contact .form-control:hover {
  cursor: url("./images/cursor-type.png"), pointer;
}

#contact .textarea {
  min-height: 150px;
  max-height: 150px;
}

#contact .send-btn {
  border-radius: 0;
  background-color: transparent;
  font-family: "Aldrich", sans-serif;
  font-weight: bold;
  padding-block: 0.5rem;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

#contact .send-btn:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
}

#contact .order-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Aldrich", sans-serif;
  font-weight: bold;
}

#contact .order-2 .informations {
  padding-block: 1rem;
}

#contact .order-2 .informations .location,
#contact .order-2 .informations .phone-number,
#contact .order-2 .informations .gmail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-block: 1rem;
}

#contact .order-2 .social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-block: 2rem;
}

#contact .order-2 .social-media a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

#contact .order-2 .social-media a:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
  transition: 0.5s;
}

#contact .order-2 .copyright {
  padding-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767.5px) {
  #contact {
    margin: 1rem;
  }
  .contact {
    padding-block: 6rem;
  }
  #contact h1 {
    max-width: 100%;
    padding-bottom: 2rem;
  }
  #contact .contact-form {
    max-width: 100%;
    margin-inline: auto;
  }
}

@media (max-width: 350.5px) {
  .contact {
    padding-inline: 0.5rem;
  }
}
