#home {
  display: block;
  margin: 2rem;
}

#home .home-card {
  min-height: 95vh;
  border: none;
  border-radius: 0;
  padding: 3rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
  box-shadow: rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

#home .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Aldrich", sans-serif;
}

#home .top-left {
  display: block;
  justify-content: flex-start;
  align-items: center;
}

#home .top-left .hi {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

#home .top-left .portfolio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

#home .top-right {
  display: block;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: end;
}

#home .top-right .available {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

#home .top-right .date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

#home .mid {
  text-align: center;
  line-height: 70%;
  font-size: 14rem;
  font-family: "Smooch Sans", sans-serif;
  font-weight: 700;
}

#home .bot {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Aldrich", sans-serif;
}

#home .bot-left {
  display: flex;
  justify-content: flex-start;
}

#home .bot-left .designed,
#home .bot-left .developed,
#home .bot-left a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

#home .bot-left a:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
}

#home .bot-left .social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#home .bot-left .social-media a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  transition: 0.5s;
}

#home .bot-left .social-media a:hover {
  cursor: url("./images/cursor-hover.png"), pointer;
}

#home .bot-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 767.5px) {
  #home {
    margin: 1rem;
  }
  #home .top,
  #home .bot {
    display: block;
  }
  #home .mid {
    font-size: 6rem;
  }
  #home .top-left,
  #home .top-right,
  #home .bot-left,
  #home .bot-right {
    display: block;
    padding-block: 2rem;
    text-align: center;
  }
}

@media (max-width: 350.5px) {
  #home .home-card {
    padding-inline: 1rem;
  }
}

@media (min-width: 1400px) {
  #home .mid {
    font-size: 17rem;
  }
}

@media (min-width: 1600px) {
  #home .mid {
    font-size: 20rem;
  }
}
