#services {
  margin: 2rem;
  box-shadow: rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
}

#services .title-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#services .title-col h1 {
  font-family: "Smooch Sans", sans-serif;
  font-weight: 700;
  font-size: 5vmax;
  text-align: center;
  max-width: 50%;
  padding-block: 3rem;
}

#services .service-col {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

#services .service-col .card {
  border-radius: 0;
}

#services .service-col .card .card-title {
  text-align: center;
  font-family: "Aldrich", sans-serif;
  font-weight: bold;
}

#services .service-col .card .card-text {
  text-align: start;
  font-family: "Aldrich", sans-serif;
  font-weight: normal;
}

@media screen and (max-width: 767.5px) {
  #services {
    margin: 1rem;
  }
  .services {
    padding-block: 6rem;
  }
  #services .title-col h1 {
    max-width: 100%;
    padding-bottom: 2rem;
  }
  #services .service-col {
    padding-inline: 1rem;
  }
}

@media (max-width: 350.5px) {
  .services {
    padding-inline: 0.5rem;
  }
}

@media screen and (min-width: 1500px) {
  #services .service-col {
    padding-inline: 6rem;
  }
}
