#about {
  margin: 2rem;
  box-shadow: rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
}

#about .about-row .text-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about .about-row .text-col h1 {
  font-family: "Smooch Sans", sans-serif;
  font-weight: 700;
  font-size: 5vmax;
  max-width: 50%;
}

#about .about-row .text-col p {
  font-family: "Aldrich", sans-serif;
  font-weight: bold;
  text-align: justify;
}

#about .about-row .text-col p .skills-title {
  text-decoration: underline;
}

#about .about-row .text-col p .skills {
  font-weight: normal;
}

#about .line-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about .line-col .line-wrap--vertical {
  width: 0;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 300px;
}

#about .line-col .line-wrap__word {
  padding: 8px;
  font-size: 1.2em;
  vertical-align: middle;
}

#about .image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about .image-col .img {
  width: 450px;
  margin: 0.5rem;
  border: none;
  padding: 0.1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
}

@media (max-width: 767.5px) {
  #about {
    margin: 1rem;
  }
  .about {
    padding-block: 6rem;
  }
  #about .about-row .text-col {
    padding-bottom: 2rem;
  }
  #about .about-row .text-col h1 {
    max-width: 100%;
    padding-bottom: 2rem;
  }
  #about .about-row .text-col p {
    max-width: 100%;
    text-align: justify;
  }
  #about .line-col {
    display: none;
  }
  #about .image-col .img {
    width: 150px;
    margin: 0.5rem;
    border: none;
    padding: 0.1rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
  }
}

@media (max-width: 350.5px) {
  .about {
    padding-inline: 0.5rem;
  }
}
