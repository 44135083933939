@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  cursor: url("./main/images/cursor.png"), pointer;
}

body {
  overflow-x: hidden;
  background-color: #6ab258;
}

/* Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #6ab258;
  border: 2px solid white;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border: 2px solid white;
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
  border: 3px solid white;
}

/*--------------------------------------------------------------------------------------------*/

/* Dark Mode */

#dark-mode {
  background-color: black;
  color: white;
}

#dark-mode .ms-auto a {
  background-color: transparent;
}

#dark-mode .ms-auto a:hover {
  background-color: white;
  color: black;
}

#dark-mode .toggler,
#dark-mode .social-media a,
#dark-mode .form-control,
#dark-mode .send-btn {
  border: 2px solid white;
  color: white;
}

#dark-mode .social-media a:hover {
  background-color: white;
  color: black;
}

#dark-mode .form-control::placeholder {
  color: white;
}

#dark-mode .send-btn:hover {
  border: 2px solid white;
  color: black;
  background-color: white;
}

#dark-mode .order-2 .social-media,
#dark-mode .order-2 .informations {
  border-bottom: 2px solid white;
}

#dark-mode .line-col .line-wrap--vertical {
  border-left: 2px solid white;
}

#dark-mode .service-col .card {
  border: 2px solid white;
}

#dark-mode .projects-col .project-card .card-title,
#dark-mode .projects-col .project-card .card-footer {
  border: 2px solid white;
}

#dark-mode .projects-col .project-card .card-body {
  border-inline: 2px solid white;
}

#dark-mode .projects-col .project-card .card-footer .div-btn .btn {
  border: 2px solid white;
  background-color: black;
  color: white;
}

#dark-mode .projects-col .project-card .card-footer .div-btn .btn:hover {
  border: 2px solid white;
  background-color: white;
  color: black;
}

#dark-mode .projects-col .project-card .card-body .card-techs .badge {
  border-left: 2px solid white;
  border-bottom: 2px solid white;
}

/*------------------------*/

/* Light Mode */

#light-mode {
  background-color: white;
  color: black;
}

#light-mode .ms-auto a {
  background-color: transparent;
}

#light-mode .ms-auto a:hover {
  background-color: black;
  color: white;
}

#light-mode .toggler,
#light-mode .social-media a,
#light-mode .form-control,
#light-mode .send-btn {
  border: 2px solid black;
  color: black;
}

#light-mode .social-media a:hover {
  background-color: black;
  color: white;
}

#light-mode .form-control::placeholder {
  color: black;
}

#light-mode .send-btn:hover {
  border: 2px solid black;
  color: white;
  background-color: black;
}

#light-mode .order-2 .social-media,
#light-mode .order-2 .informations {
  border-bottom: 2px solid black;
}

#light-mode .line-col .line-wrap--vertical {
  border-left: 2px solid black;
}

#light-mode .service-col .card {
  border: 2px solid black;
}

#light-mode .projects-col .project-card .card-title,
#light-mode .projects-col .project-card .card-footer {
  border: 2px solid black;
}

#light-mode .projects-col .project-card .card-body {
  border-inline: 2px solid black;
}

#light-mode .projects-col .project-card .card-footer .div-btn .btn {
  border: 2px solid black;
  background-color: white;
  color: black;
}

#light-mode .projects-col .project-card .card-footer .div-btn .btn:hover {
  border: 2px solid black;
  background-color: black;
  color: white;
}

#light-mode .projects-col .project-card .card-body .card-techs .badge {
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}
